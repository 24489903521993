// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-stream-js": () => import("./../src/pages/live-stream.js" /* webpackChunkName: "component---src-pages-live-stream-js" */),
  "component---src-pages-live-stream-nocdn-js": () => import("./../src/pages/live-stream-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-nocdn-js" */),
  "component---src-pages-live-stream-source-js": () => import("./../src/pages/live-stream-source.js" /* webpackChunkName: "component---src-pages-live-stream-source-js" */),
  "component---src-pages-live-stream-source-nocdn-js": () => import("./../src/pages/live-stream-source-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-source-nocdn-js" */),
  "component---src-pages-live-stream-test-js": () => import("./../src/pages/live-stream-test.js" /* webpackChunkName: "component---src-pages-live-stream-test-js" */),
  "component---src-pages-live-stream-test-nocdn-js": () => import("./../src/pages/live-stream-test-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-test-nocdn-js" */),
  "component---src-pages-live-stream-test-source-js": () => import("./../src/pages/live-stream-test-source.js" /* webpackChunkName: "component---src-pages-live-stream-test-source-js" */),
  "component---src-pages-live-stream-test-source-nocdn-js": () => import("./../src/pages/live-stream-test-source-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-test-source-nocdn-js" */),
  "component---src-pages-live-stream-twitch-js": () => import("./../src/pages/live-stream-twitch.js" /* webpackChunkName: "component---src-pages-live-stream-twitch-js" */),
  "component---src-pages-live-stream-vj-js": () => import("./../src/pages/live-stream-vj.js" /* webpackChunkName: "component---src-pages-live-stream-vj-js" */),
  "component---src-pages-live-stream-vj-nocdn-js": () => import("./../src/pages/live-stream-vj-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-vj-nocdn-js" */),
  "component---src-pages-live-stream-vj-source-js": () => import("./../src/pages/live-stream-vj-source.js" /* webpackChunkName: "component---src-pages-live-stream-vj-source-js" */),
  "component---src-pages-live-stream-vj-source-nocdn-js": () => import("./../src/pages/live-stream-vj-source-nocdn.js" /* webpackChunkName: "component---src-pages-live-stream-vj-source-nocdn-js" */),
  "component---src-pages-player-js": () => import("./../src/pages/player.js" /* webpackChunkName: "component---src-pages-player-js" */)
}

